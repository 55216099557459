/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo({ post }) {
    const siteData = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        author
                    }
                }
                wp {
                    generalSettings {
                        title
                        description
                    }
                }
            }
        `
    )

    const site = {
        siteUrl: siteData.site.siteMetadata?.siteUrl,
        title: siteData.wp.generalSettings.title,
        description: siteData.wp.generalSettings.description,
        defaultImage:
            'https://share.trustpilot.com/images/company-rating?locale=en-GB&businessUnitId=4f8eb229000064000514888c',
        author: siteData.site.siteMetadata?.author
    }

    const metaTags = {
        title: post.seo?.title,
        description: post.seo?.metaDesc || site.description,
        canonical: post.seo?.canonical,
        lang: post.lang
    }

    const robots = {
        indexStatus: post.seo?.metaRobotsNoindex,
        followStatus: post.seo?.metaRobotsNofollow
    }

    return (
        <Helmet
            htmlAttributes={{
                lang: metaTags.lang
            }}
            title={metaTags.title}
            titleTemplate={site.title ? '%s' : null}
            meta={[
                {
                    name: 'robots',
                    content: `${robots.indexStatus}, ${robots.followStatus}`
                },
                {
                    name: 'description',
                    content: metaTags.description
                },
                {
                    name: 'author',
                    content: site.author
                },
                {
                    property: 'og:title',
                    content: metaTags.title
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image',
                    content: site.defaultImage
                },
                {
                    property: 'og:url',
                    content: metaTags.canonical
                },
                {
                    property: 'og:description',
                    content: metaTags.description
                },
                {
                    property: 'og:site_name',
                    content: `${site.title} Ltd`
                },
                {
                    property: 'fb:app_id',
                    content: '1507219169577312'
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    name: 'twitter:title',
                    content: metaTags.title
                },
                {
                    name: 'twitter:description',
                    content: metaTags.description
                }
            ]}
            link={[
                {
                    rel: 'canonical',
                    href: `${site.siteUrl}${metaTags.canonical}`
                }
            ]}
        />
    )
}

Seo.propTypes = {
    post: PropTypes.shape({
        lang: PropTypes.string,
        seo: PropTypes.shape({
            canonical: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            metaRobotsNoindex: PropTypes.string,
            metaRobotsNofollow: PropTypes.string
        })
    })
}

Seo.defaultProps = {
    post: {
        lang: 'en',
        seo: {
            metaRobotsNoindex: 'index',
            metaRobotsNofollow: 'follow'
        }
    }
}

export default Seo

export const query = graphql`
    fragment SeoForPage on WpPage {
        seo {
            canonical
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
        }
    }
    fragment SeoForInsurer on WpInsurer {
        seo {
            canonical
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
        }
    }
    fragment SeoForAdvertorial on WpAdvertorial {
        seo {
            canonical
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
        }
    }
    fragment SeoForHelpCategory on WpHelpCategory {
        seo {
            canonical
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
        }
    }
    fragment SeoForHelp on WpHelp {
        seo {
            canonical
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
        }
    }
`
